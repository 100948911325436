import { useState } from "react"
import axios from "axios"

function JoinEmailListComponent() {

    const [email, setEmail] = useState("")
    const [submitting, setSubmitting] = useState(false)
    const [success, setSuccess] = useState(false)
    const [role, setRole] = useState("")
    const roles = ["Student", "Parent", "Tutor"]
    const [showError, setShowError] = useState(false)

    async function submitClicked() {
        console.log("submit now: ", email)
        if (submitting) { return }
        if (isValidEmail(email) && role !== "") {
            console.log("valid email")
            setSubmitting(true)
            const res = await axios.post('https://addtomailchimp-js6rti2rea-uc.a.run.app', { email: email, role: role })
            setSuccess(true)
            setSubmitting(false)
            console.log(res)
        } else {
            setShowError(true)
        }
    }

    function isValidEmail(email) {
        if (!email) { return false }
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }

    function RoleSelector() {
        return (
        <div className="flex flex-row space-x-2">
            {
                roles.map(u => {
                    return <button className={`border-2 flex-grow rounded w-full p-2 font-medium ${u === role ? "bg-darkGreen border-darkGreen text-white" : "bg-gray-100 text-black hover:bg-green-800 hover:text-white hover:border-green-800"} ${showError && role === "" ? "border-2 border-red-600" : ""}`} key={u} onClick={(e) => { setRole(u) }}>{u}</button>
                })
            }
        </div>)

    }

    return <div className="bg-white w-full h-full p-4 flex flex-col space-y-2">
        <div className="text-lg font-semibold text-center">{success ? "Success! Your email is on the list:" : "Join Our Invite List"}</div>
        {
            success ? <div className="text-center">{email}</div> :
                submitting ? <div className="text-center">Submitting...</div> :
                    <>
                        <RoleSelector/>
                        <input className={`bg-white border-2 rounded px-2 py-1 text-lg ${showError && !isValidEmail(email) ? "border-red-600" : ""} `} placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                        <button disabled={!(isValidEmail(email) && role !== "")} className="bg-green-600 disabled:bg-gray-200 disabled:text-gray-400 hover:bg-green-800 rounded p-2 text-white font-medium" onClick={submitClicked}>Submit</button>
                    </>
        }

    </div>
}

export default JoinEmailListComponent