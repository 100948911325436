import { OpenAI } from "openai"

const openai = new OpenAI({ apiKey:  process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true })

const schema = {
    type: "object",
    properties: {
        passage_content: {
            type: "string",
            description: "Get the passage referenced in the question. If the question does not reference a passage, this is an empty string."
        },
        question_content: {
            type: "string",
            description: "Get the full test question, formatted as LaTeX."
            // description: "Get the full question text including any passages or accompanying text, formatted as LaTeX, without the answers."
        },
        answers: {
            type: "array",
            description: "Get the answer options for the test question, formatted as LaTeX with dollar sign delimiters.",
            items: {
                type: "object",
                properties: {
                    id: {type: "string"},
                    text: {type: "string"}
                }
            }
        },
        correct_answer_id: {
            type: "string",
            description: "Get the id of the correct answer to the question, formatted as LaTeX with dollar sign delimiters."
        },
        answer_rationale: {
            type: "string",
            description: "Get the explanation or rationale for how one arrives at the correct answer, formatted as LaTeX with dollar sign delimiters."
        }
    }
}

async function generateSATQuestion(subject, domain, skill, difficulty) {
    console.log('about to generate sat question')
    const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        messages: [
            { role: "system", content: "You are an SAT test prep assistant."},
            { role: "user", content: `Generate a multiple choice SAT question with subject: ${subject}, in domain: ${domain}, focused on skill: ${skill}, with difficulty level: ${difficulty}`}
        ],
        functions: [
            { name: "create_sat_question", parameters: schema }
        ],
        function_call: { name: "create_sat_question" }
    })
    console.log('got response')

    try {
        const str = response.choices[0].message.function_call.arguments
        str.replaceAll("\frac","\\frac")
        str.replaceAll("\sqrt","\\sqrt")
        // str.replaceAll(/\\/g, "\\\\")
        const json = JSON.parse(str)

        // const json = JSON.parse(response.choices[0].message.function_call.arguments)
        console.log('question json: ', json)
        return json
    } catch (e) {
        console.log('error parsing question json: ', e)
        console.log(response.choices[0].message.function_call.arguments)
        return {}
    }
}

export { generateSATQuestion } 