import { Link, useNavigate } from 'react-router-dom'
import NavBar from './NavBar';
import { useSession } from '../firebase/firebase';
import { useEffect } from 'react';
import JoinEmailListComponent from './JoinEmailListComponent';
// import studyImg from '../resources/library-study.png'

function LandingPage() {

    // const user = useSession()
    // const navigate = useNavigate()

    // useEffect(() => {
    //     if (user) {
    //         navigate('/dashboard')
    //     }
    // }, [])

    return (
        <div className='flex flex-col h-screen'>
            <NavBar />
            <div className='items-center bg-cover bg-center bg-darkGreen/60 bg-blend-overlay bg-[url("../public/images/sycamore_hero.png")] flex flex-grow'>
                <div className="m-auto items-center w-3/4 md:w-1/2 xl:w-1/3 flex flex-col space-y-12">
                    <div className='text-white text-3xl font-bold'>AI-Powered SAT Prep Tools</div>
                    <JoinEmailListComponent/>

                    {/* <label>Study Mate</label>
                    <Link to={'/signup'}>
                        <button className="bg-white px-4 py-2">get started</button>
                    </Link> */}
                </div>
            </div>
        </div>

    )
}

export default LandingPage;