import logo from './logo.svg';
import './App.css';
import LandingPage from './components/LandingPage';
import { Routes, Route, useNavigation } from 'react-router-dom';
import AuthenticationPage from './components/AuthenticationPage';
import DashboardPage from './components/DashboardPage';
import { useAuth, userContext } from './firebase/firebase';
import LoadingComponent from './components/LoadingComponent';
import StudySetEditorPage from './components/flashcards/StudySetEditorPage';
import StudySetOverviewPage from './components/flashcards/StudySetOverviewPage';
import StudySetPracticePage from './components/flashcards/StudySetPracticePage';
// import StudySetEditorPage from './components/Flashcards/StudySetEditorPage';
// import StudySetOverviewPage from './components/Flashcards/StudySetOverviewPage';
// import StudySetPracticePage from './components/Flashcards/StudySetPracticePage';
import MultipleChoiceQuestionComponent from './components/multiple-choice/MultipleChoiceQuestionComponent';
import CreateQuestionPage from './components/multiple-choice/CreateQuestionPage';
import FirestoreQuestionPage from './components/multiple-choice/FirestoreQuestionPage';

function App() {
  const { initializing, user } = useAuth()

  if (initializing) {
    return (<LoadingComponent />)
  } else {
    return (
      <userContext.Provider value={{ user }}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<LandingPage />} />
          {/* <Route path='/signup' element={<AuthenticationPage signup={true} />} />
          <Route path='/login' element={<AuthenticationPage signup={false} />} />
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path='/createStudySet' element={<StudySetEditorPage/>} />
          <Route path='/studySet/:id' element={<StudySetOverviewPage />}/>
          <Route path='/studySet/:id/edit' element={<StudySetEditorPage />}/>
          <Route path='/studySet/:id/practice' element={<StudySetPracticePage />}/>
          <Route path='/testquestion' element={<MultipleChoiceQuestionComponent/>}/>
          <Route path='/createSATQuestion' element={<CreateQuestionPage/>}/>
          <Route path='/question/:id' element={<FirestoreQuestionPage/>}/> */}
        </Routes>
      </userContext.Provider>
    )
  }
}

export default App;
