import { Link } from 'react-router-dom'
import sycPrepLogo from '../images/sycamore_prep_cropped_300.png'
import { logout, useSession } from '../firebase/firebase'

function NavBar() {
    const user = useSession()

    function RightSideButtons() {
        return (
            <div className="flex flex-row space-x-2">
                {
                    user ?
                        (<div className='text-white text-sm' onClick={() => logout()}>{user.email}</div>) :
                        (<><Link to={'/login'}>
                            <button className="border rounded px-2 text-white">login</button>
                        </Link>
                            <Link to={'/signup'}>
                                <button className="border rounded px-2 text-white">sign up</button>
                            </Link></>)
                }
            </div>
        )
    }

    return (
        <nav className="flex items-center justify-between flex-wrap bg-darkGreen p-3">
            <Link to={'/dashboard'}>
                <div className="flex items-center text-white">
                    <img src={sycPrepLogo} className='h-10 mr-3' />
                    <div className="font-bold text-xl">Sycamore Prep</div>
                </div>
            </Link>
            <a href='mailto:contact@sycamoreprep.com'>
                <button className={`text-white text-sm hover:bg-white hover:text-darkGreen border font-medium rounded px-4 py-1`}>Contact</button>
            </a>
            
            {/* <RightSideButtons /> */}
        </nav>
    )
}

export default NavBar